























































































import EnderecoDTO from "@/models/EnderecoDTO";
import { api } from "@/services";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
@Component({
  components: {},
})
export default class EnderecoInput extends Vue {
  @Prop({ required: true }) target!: EnderecoDTO;
  @Prop({ default: false }) readonly!: boolean;

  mounted() {
    this.endereco = this.target;
  }
  @Watch("endereco")
  changeEndereco() {
    if (this.endereco != undefined) this.$emit("update:target", this.endereco);
  }
  @Watch("target")
  changeTarget(newValue: EnderecoDTO) {
    this.endereco = newValue;
  }

  loadCep = false;
  endereco = new EnderecoDTO();
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  formatUf() {
    const vm = this;
    vm.endereco.uf = vm.endereco.uf.toLocaleUpperCase();
  }
  async buscaCep() {
    this.loadCep = true;
    try {
      const cep = this.endereco.cep.replace("-", "");
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      this.loadCep = false;
      if (response.data.erro == undefined) {
        this.endereco = {
          ...this.endereco,
          cep: response.data.cep,
          street: response.data.logradouro,
          country: response.data.bairro,
          uf: response.data.uf,
          city: response.data.localidade,
        };
      } else {
        this.loadCep = false;
        this.$store.commit("setError", "CEP não encontrado");
      }
    } catch (error) {
      this.loadCep = false;
      this.$store.commit("setError", "CEP não encontrado");
    }
  }
}
