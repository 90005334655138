



























































































































































import Vue from "vue";
import Component from "vue-class-component";
import EnderecoDTO from "@/models/EnderecoDTO";
import EnderecoInput from "@/components/EnderecoInput.vue";
import { Prop, Watch } from "vue-property-decorator";
import MedicoService from "@/services/MedicoService";
import PessoaDTO from "@/models/PessoaDTO";
import MedicoDTO from "@/models/MedicoDTO";
import PacienteService from "@/services/PacienteService";
import moment from "moment";
import ConsultaDTO from "@/models/ConsultaDTO";
import ConsultaService from "@/services/ConsultaService";

@Component({
  components: {
    EnderecoInput,
  },
})
export default class DialogActionConsultas extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() consultaId!: number;
  @Prop({ default: "add" }) modeInit!: string;

  mode = "add";
  dialogDelete = false;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
    if (this.mode !== "add") {
      this.findMedicos();
      this.findPaciente();
    }
  }
  @Watch("consultaId")
  changePessoaId() {
    console.log("changePessoaId");
    if (this.mode !== "add") {
      this.find();
      this.findMedicos();
      this.findPaciente();
    }
  }
  @Watch("modeInit")
  changeModeInit() {
    this.mode = this.modeInit;
    console.log("changeMode");
    if (this.mode !== "add") {
      this.find();
      this.findMedicos();
      this.findPaciente();
    }
  }

  @Watch("mode")
  changeMode() {
    if (this.mode !== "add") {
      this.findMedicos();
      this.findPaciente();
    }
  }
  menuData = false;
  medico: MedicoDTO | string = "";
  medicos: any[] = [];

  paciente: PessoaDTO | string = "";
  pacientes: any[] = [];
  observação = "";
  observacoes: string[] = [];
  date = moment().format("YYYY-MM-DD");
  time = moment().format("HH:mm");

  get dateFormat() {
    const vetData = this.date.split("-");
    return `${vetData[2]}/${vetData[1]}/${vetData[0]} ${this.time}`;
  }
  changeObs(obs: string, index: number) {
    console.log("obs", obs);
    this.$set(this.observacoes, index, obs);
  }
  removeObs(index: number) {
    this.observacoes.splice(index, 1);
  }
  async findMedicos() {
    this.medicos = (await MedicoService.list()).map((el) => ({
      text: el.name,
      value: el,
    }));
  }
  async findPaciente() {
    this.pacientes = (await PacienteService.list()).map((el) => ({
      text: el.name,
      value: el,
    }));
  }
  async deletePessoa() {
    await ConsultaService.delete(this.consultaId);
    this.dialogDelete = false;
    this.dialog = false;
  }
  async find() {
    const consulta = await ConsultaService.find(this.consultaId);
    this.medico = (await MedicoService.find(consulta.doctorId)).name;
    this.paciente = (await PacienteService.find(consulta.patientId)).name;
    this.observacoes = consulta.observations;

    const dataHora = consulta.consultationDate.split(" ");
    const data = dataHora[0];
    const hora = dataHora[1];
    const dataVet = data.split("/");
    this.date = `${dataVet[2]}-${dataVet[1]}-${dataVet[0]}`;
    this.time = hora;
  }

  async save() {
    try {
      let consulta = new ConsultaDTO();
      consulta.consultationDate = this.dateFormat;
      if ((this.medico as any).value) {
        consulta.doctorId = ((this.medico as any).value as MedicoDTO).id;
      } else {
        consulta.doctorId = this.medicos.find(
          (el) => el.text == this.medico
        ).value.id;
      }
      if ((this.paciente as any).value) {
        consulta.patientId = ((this.paciente as any).value as PessoaDTO).id;
      } else {
        consulta.patientId = this.pacientes.find(
          (el) => el.text == this.paciente
        ).value.id;
      }
      consulta.scheduled = moment().format("DD/MM/YYYY HH:mm");
      if (this.mode == "add") {
        consulta.observations.push(this.observação);
        await ConsultaService.create(consulta);
      }
      if (this.mode == "edit") {
        consulta.id = this.consultaId;
        consulta.observations = this.observacoes;
        await ConsultaService.update(consulta);
      }
      this.dialog = false;
    } catch (error) {
      console.log(error);
    }
  }
  mounted() {
    this.mode = this.modeInit;
    if (this.mode == "add" || this.mode == "edit") {
      this.findMedicos();
      this.findPaciente();
    }
    if (this.mode == "view" || this.mode == "edit") {
      this.find();
    }
  }
  constructor() {
    super();
  }
}
