






















































import { Component, Vue, Watch } from "vue-property-decorator";

import TopBar from "@/components/TopBar.vue";
import CardMain from "@/components/CardMain.vue";
import DialogActionAtendentes from "@/components/atendentes/DialogActionAtendentes.vue";
import DialogDeleteAtendentes from "@/components/atendentes/DialogDeleteAtendentes.vue";
import AtendenteService from "@/services/AtendenteService";
import moment from "moment";
import PessoaTable from "@/models/PessoaTable";

interface MenuItem {
  titulo: string;
  descricao: string;
  to: string;
}

@Component({
  components: {
    TopBar,
    CardMain,
    DialogActionAtendentes,
    DialogDeleteAtendentes,
  },
})
export default class AtendentesListar extends Vue {
  headers: any[] = [
    { text: "Nome", value: "nome" },
    { text: "CPF", value: "cpf" },
    { text: "Idade", value: "idade" },
    { text: " ", value: "action" },
  ];
  atendentesSearch: PessoaTable[] = [];
  atendentes: PessoaTable[] = [];

  dialogEdit = false;
  dialogApaga = false;
  dialogView = false;
  idTarget = 0;

  searchText = "";

  @Watch("dialogEdit")
  changedialogEdit(val: boolean) {
    if (!val) this.list();
  }

  @Watch("dialogApaga")
  changedialogApaga(val: boolean) {
    if (!val) this.list();
  }
  search() {
    this.atendentesSearch = this.atendentes.filter(
      (el) =>
        el.nome.toLowerCase().includes(this.searchText.toLowerCase()) ||
        el.cpf.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  async list() {
    const list = await AtendenteService.list();
    this.atendentes = list.map((item: any) => {
      const today = moment().format("yyyy");
      const dataVet = item.birth.split("/");
      const birth = moment(`${dataVet[2]}/${dataVet[1]}/${dataVet[0]}`).format(
        "yyyy"
      );
      return {
        id: item.id,
        nome: item.name,
        cpf: item.document,
        idade: +today - +birth,
      };
    });
    this.search();
  }

  openVer(id: number) {
    this.idTarget = id;
    this.dialogView = true;
  }

  openEditar(id: number) {
    this.idTarget = id;
    this.dialogEdit = true;
  }

  openApagar(id: number) {
    this.idTarget = id;
    this.dialogApaga = true;
  }
  constructor() {
    super();
    this.list();
  }
}
