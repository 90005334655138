



























































































import Vue from "vue";
import Component from "vue-class-component";
import EnderecoDTO from "@/models/EnderecoDTO";
import EnderecoInput from "@/components/EnderecoInput.vue";
import { Prop, Watch } from "vue-property-decorator";
import MedicoService from "@/services/MedicoService";
import MedicoDTO from "@/models/MedicoDTO";

@Component({
  components: {
    EnderecoInput,
  },
})
export default class DialogActionMedico extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() pessoaId!: number;
  @Prop({ default: "add" }) mode!: string;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("pessoaId")
  changePessoaId() {
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("mode")
  changeMode() {
    if (this.mode !== "add") {
      this.find();
    }
  }

  cpf = "";
  crm = "";
  nome = "";
  dataNascimento = "";
  genero = "Masculino";
  endereco = new EnderecoDTO();
  async find() {
    const pessoa = (await MedicoService.find(this.pessoaId)) as any;
    this.crm = pessoa.crm;
    this.cpf = pessoa.document;
    this.nome = pessoa.name;
    this.genero = pessoa.gender == "MALE" ? "Masculino" : "Feminino";
    this.dataNascimento = pessoa.birth;

    this.endereco.cep = pessoa.address.cep;
    this.endereco.street = pessoa.address.street;
    this.endereco.number = pessoa.address.number;
    this.endereco.country = pessoa.address.country;
    this.endereco.city = pessoa.address.city;
    this.endereco.uf = pessoa.address.uf;
  }
  async save() {
    try {
      let pessoa = new MedicoDTO();
      pessoa.crm = this.crm;
      pessoa.document = this.cpf;
      pessoa.name = this.nome;
      pessoa.username = this.cpf;
      pessoa.password = "senha123";

      const dataVet = this.dataNascimento.split("/");
      pessoa.birth = `${dataVet[0]}/${dataVet[1]}/${dataVet[2]}`;
      pessoa.gender = this.genero == "Masculino" ? "MALE" : "FEMALE";
      pessoa.address.cep = this.endereco.cep;
      pessoa.address.street = this.endereco.street;
      pessoa.address.number = this.endereco.number;
      pessoa.address.country = this.endereco.country;
      pessoa.address.city = this.endereco.city;
      pessoa.address.uf = this.endereco.uf;
      if (this.mode == "add") await MedicoService.create(pessoa);
      if (this.mode == "edit") {
        pessoa.id = this.pessoaId;
        await MedicoService.update(pessoa);
      }
      this.dialog = false;
    } catch (error) {
      console.log(error);
    }
  }
  constructor() {
    super();
  }
}
