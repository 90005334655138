


















































































import Vue from "vue";
import Component from "vue-class-component";
import EnderecoDTO from "@/models/EnderecoDTO";
import EnderecoInput from "@/components/EnderecoInput.vue";
import PessoaDTO from "@/models/PessoaDTO";
import AtendenteService from "@/services/AtendenteService";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    EnderecoInput,
  },
})
export default class DialogActionAtendentes extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() pessoaId!: number;
  @Prop({ default: "add" }) mode!: string;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("pessoaId")
  changePessoaId() {
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("mode")
  changeMode() {
    if (this.mode !== "add") {
      this.find();
    }
  }

  cpf = "";
  nome = "";
  dataNascimento = "";
  genero = "Masculino";
  endereco = new EnderecoDTO();
  async find() {
    const pessoa = (await AtendenteService.find(this.pessoaId)) as any;
    this.cpf = pessoa.document;
    this.nome = pessoa.name;
    this.genero = pessoa.gender == "MALE" ? "Masculino" : "Feminino";
    this.dataNascimento = pessoa.birth;

    this.endereco = pessoa.address;
    // this.endereco.rua = pessoa.address.street;
    // this.endereco.numero = pessoa.address.number;
    // this.endereco.bairro = pessoa.address.country;
    // this.endereco.cidade = pessoa.address.city;
    // this.endereco.uf = pessoa.address.uf;
  }
  async save() {
    let pessoa = new PessoaDTO();
    pessoa.document = this.cpf;
    pessoa.name = this.nome;
    pessoa.username = this.cpf;
    pessoa.password = "senha123";

    const dataVet = this.dataNascimento.split("/");
    pessoa.birth = `${dataVet[0]}/${dataVet[1]}/${dataVet[2]}`;
    pessoa.gender = this.genero == "Masculino" ? "MALE" : "FEMALE";
    pessoa.address = this.endereco;
    // pessoa.address.street = this.endereco.rua;
    // pessoa.address.number = this.endereco.numero;
    // pessoa.address.country = this.endereco.bairro;
    // pessoa.address.city = this.endereco.cidade;
    // pessoa.address.uf = this.endereco.uf;
    if (this.mode == "add") await AtendenteService.create(pessoa);
    if (this.mode == "edit") {
      pessoa.id = this.pessoaId;
      await AtendenteService.update(pessoa);
    }
    this.dialog = false;
  }
  constructor() {
    super();
  }
}
