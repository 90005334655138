





























import Vue from "vue";
import Component from "vue-class-component";
import ActionPaciente from "@/components/paciente/ActionPaciente.vue";
import EnderecoInput from "@/components/EnderecoInput.vue";
import PessoaDTO from "@/models/PessoaDTO";
import PacienteService from "@/services/PacienteService";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    EnderecoInput,
    ActionPaciente,
  },
})
export default class DialogActionPaciente extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() pessoaId!: number;
  @Prop({ default: "add" }) mode!: string;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("pessoaId")
  changePessoaId() {
    if (this.mode !== "add") {
      this.find();
    }
  }
  @Watch("mode")
  changeMode() {
    if (this.mode !== "add") {
      this.find();
    }
  }

  cpf = "";
  nome = "";
  dataNascimento = "";
  genero = "Masculino";

  pessoa = new PessoaDTO();
  async find() {
    this.pessoa = (await PacienteService.find(this.pessoaId)) as any;
  }
  async save() {
    this.pessoa.username = this.pessoa.document;
    this.pessoa.password = "senha123";

    if (this.mode == "add") await PacienteService.create(this.pessoa);
    if (this.mode == "edit") {
      this.pessoa.id = this.pessoaId;
      await PacienteService.update(this.pessoa);
    }
    this.dialog = false;
  }
  constructor() {
    super();
    this.pessoa.medaData = [
      {
        id: 0,
        key: "Restrições médicas",
        value: "",
        createdAt: new Date(),
      },
      {
        id: 0,
        key: "Observação",
        value: "",
        createdAt: new Date(),
      },
      {
        id: 0,
        key: "Contato de um terceiro",
        value: "",
        createdAt: new Date(),
      },
      {
        id: 0,
        key: "Tipo Sanguíneo",
        value: "",
        createdAt: new Date(),
      },
    ];
  }
}
