
















import Vue from "vue";
import Component from "vue-class-component";
import PacienteService from "@/services/PacienteService";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DialogDeletePaciente extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() pessoaId!: number;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
  }
  async deletePessoa() {
    await PacienteService.delete(this.pessoaId);
    this.dialog = false;
  }
}
