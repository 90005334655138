var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.mode == 'add')?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Cadastro consulta ")]):_vm._e(),(_vm.mode == 'view')?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Consulta "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":function () {
            _vm.mode = 'edit';
          }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function () {
            _vm.dialogDelete = true;
          }}},[_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-delete")])],1)],1):_vm._e(),(_vm.mode == 'edit')?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Alterar consulta ")]):_vm._e(),_c('div',{staticStyle:{"margin":"20px"}},[_c('v-combobox',{attrs:{"readonly":_vm.mode == 'view',"items":_vm.medicos,"label":"Médico","outlined":"","dense":""},model:{value:(_vm.medico),callback:function ($$v) {_vm.medico=$$v},expression:"medico"}}),_c('v-combobox',{attrs:{"readonly":_vm.mode == 'view',"items":_vm.pacientes,"label":"Paciente","outlined":"","dense":""},model:{value:(_vm.paciente),callback:function ($$v) {_vm.paciente=$$v},expression:"paciente"}}),_c('v-menu',{ref:"menuData",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Data","dense":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuData),callback:function ($$v) {_vm.menuData=$$v},expression:"menuData"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-time-picker',{attrs:{"no-title":"","scrollable":"","format":"24hr"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuData = false}}},[_vm._v(" OK ")])],1)],1)],1),(_vm.mode == 'add')?_c('v-text-field',{attrs:{"label":"Observação","type":"text","outlined":"","dense":""},model:{value:(_vm.observação),callback:function ($$v) {_vm.observação=$$v},expression:"observação"}}):_vm._e(),_vm._l((_vm.observacoes),function(obs,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('v-text-field',{attrs:{"readonly":_vm.mode == 'view',"label":"Observação","type":"text","outlined":"","dense":""},model:{value:(_vm.observacoes[index]),callback:function ($$v) {_vm.$set(_vm.observacoes, index, $$v)},expression:"observacoes[index]"}}),(_vm.mode == 'edit')?_c('v-btn',{attrs:{"height":"40"},on:{"click":function () {
              _vm.removeObs(index);
            }}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)}),(_vm.mode == 'edit')?_c('v-btn',{attrs:{"block":""},on:{"click":function () {
            _vm.observacoes.push('');
          }}},[_vm._v("Adicionar"),_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),(_vm.mode == 'add')?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Cadastrar ")]):_vm._e(),(_vm.mode == 'edit')?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Alterar ")]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Apagar consulta")]),_c('v-card-text',[_vm._v(" Tem certeza que deseja apagar essa consulta? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){return _vm.deletePessoa()}}},[_vm._v(" Apagar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }