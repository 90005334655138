

































import { Component, Vue } from "vue-property-decorator";

import TopBar from "@/components/TopBar.vue";
import UserDTO from "@/models/UserDTO";
import ConsultaDTO from "@/models/ConsultaDTO";
import ConsultaService from "@/services/ConsultaService";
import MedicoService from "@/services/MedicoService";
//import DialogActionUsuario from "@/components/usuario/DialogActionUsuario.vue";
type Consulta = {
  local: string;
  medico: string;
  data: string;
};
@Component({
  components: {
    TopBar,
  },
})
export default class HomeUsuario extends Vue {
  dialogView = false;

  get user() {
    return this["$store"].state.user as UserDTO;
  }
  headers: any[] = [
    { text: "Local", value: "local" },
    { text: "Medico", value: "medico" },
    { text: "Data", value: "data" },
  ];

  consultas: Consulta[] = [];

  async list() {
    const list = await ConsultaService.list();
    for (const iterator of list) {
      iterator.doctor = await MedicoService.find(iterator.doctorId);
    }
    this.consultas = list.map((el) => ({
      local: "Clínica Medbay",
      medico: el.doctor.name,
      data: el.consultationDate,
    }));
  }
  mounted() {
    this.list();
  }
}
