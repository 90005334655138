



































































import Vue from "vue";
import Component from "vue-class-component";
import EnderecoInput from "@/components/EnderecoInput.vue";
import PessoaDTO from "@/models/PessoaDTO";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    EnderecoInput,
  },
})
export default class ActionPaciente extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ required: true }) target!: PessoaDTO;

  constructor() {
    super();
  }
}
