





































import { Component, Vue } from "vue-property-decorator";

import TopBar from "@/components/TopBar.vue";
import Consulta from "@/models/Consulta";
//import DialogActionUsuario from "@/components/usuario/DialogActionUsuario.vue";

@Component({
  components: {
    TopBar,
    // DialogActionUsuario,
  },
})
export default class HomeUsuario extends Vue {
  dialogView = false;
  headers: any[] = [
    { text: "Nome", value: "nome" },
    { text: "Local", value: "local" },
    { text: "Valor", value: "valor" },
    { text: "Data", value: "data" },
    { text: " ", value: "action" },
  ];

      consultas: Consulta[] = [
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
        {
        nome: "Consulta de vista",
        local: "Rua das Flores",
        valor: 40,
        data: "03/04/200"

        },
    ]
}

