











import Vue from "vue";
import jwt_decode from "jwt-decode";
import UserDTO from "../models/UserDTO";
import Component from "vue-class-component";

@Component
export default class TopBar extends Vue {
  logout() {
    this.$router.push("/login");
  }

  constructor() {
    super();
    const token = localStorage.getItem("token");

    if (token == null) {
      this.$router.push("/login");
    } else {
      var decoded = jwt_decode(token) as any;
      console.log("decoded", decoded);
      var user = new UserDTO();
      user.username = decoded.sub;
      user.roles = decoded.ROLE;
      user.name = decoded.NAME;
      this.$store.commit("setUser", user);
    }
  }
}
