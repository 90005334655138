






















































import Vue from "vue";
import Component from "vue-class-component";
import AuthDTO from "@/models/AuthDTO";
import AuthService from "@/services/AuthService";

@Component
export default class LoginPage extends Vue {
  auth: AuthDTO = new AuthDTO();
  loadingLogin: boolean = false;

  async login() {
    this.loadingLogin = true;
    if ((this.$refs as any).form.validate()) {
      try {
        const token = await AuthService.login(this.auth);
        localStorage.setItem("token", token);
        this.$router.push("/");
      } catch (error) {
        this.$store.commit("setError", error);
      }
      this.loadingLogin = false;
    }
  }
}
