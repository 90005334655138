


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DialogActionMedico from "@/components/medicos/DialogActionMedico.vue";
import DialogActionAtendentes from "@/components/atendentes/DialogActionAtendentes.vue";
import DialogActionPaciente from "@/components/paciente/DialogActionPaciente.vue";
import DialogActionConsultas from "./consultas/DialogActionConsultas.vue";

@Component({
  components: {
    DialogActionMedico,
    DialogActionPaciente,
    DialogActionAtendentes,
    DialogActionConsultas,
  },
})
export default class CardMain extends Vue {
  @Prop({ required: true }) readonly titulo?: string;
  @Prop({ required: true }) readonly img?: string;
  @Prop({ default: "medico" }) readonly mode?: string;

  dialog = false;
  constructor() {
    super();
  }

  @Watch("dialog")
  changeDialog(val: boolean) {
    (this["$parent"] as any).list();
  }

  getImgUrl(url: string) {
    var images = require.context("../assets/", false, /\.png$/);
    return images("./" + url + ".png");
  }

  back() {
    this.$router.push("/");
  }
}
