
























































import { Component, Vue, Watch } from "vue-property-decorator";

import TopBar from "@/components/TopBar.vue";
import CardMain from "@/components/CardMain.vue";
import ConsultaService from "@/services/ConsultaService";
import PacienteService from "@/services/PacienteService";
import DialogMedicoConsultas from "@/components/consultas/DialogMedicoConsultas.vue";
import PessoaDTO from "@/models/PessoaDTO";
interface CalendarType {
  text: string;
  value: string;
}
@Component({
  components: {
    TopBar,
    CardMain,
    DialogMedicoConsultas,
  },
})
export default class ConsultaMedicoListar extends Vue {
  type: string = "day";
  types: CalendarType[] = [
    { text: "Mensal", value: "month" },
    { text: "Semanal", value: "week" },
    { text: "Dia", value: "day" },
  ];
  weekday = [0, 1, 2, 3, 4, 5, 6];
  value = "";
  events: any[] = [];
  colors = ["blue", "indigo", "deep-purple", "cyan", "green", "orange"];

  dialogEdit = false;
  dialogView = false;
  idTarget = 0;

  searchText = "";

  @Watch("dialogEdit")
  changedialogEdit(val: boolean) {
    if (!val) this.getEvents();
  }

  async list() {
    const list = await ConsultaService.list();
    for (const iterator of list) {
      iterator.patient = await PacienteService.find(iterator.patientId);
    }

    return list;
  }
  backToMenu() {
    this.$router.push("/");
  }
  openVer(e: any) {
    this.idTarget = e.event.id;
    this.dialogView = true;
  }

  openEditar(id: number) {
    this.idTarget = id;
    this.dialogEdit = true;
  }

  async getEvents() {
    const events = [];
    const consultas = await this.list();
    console.log("consultas", consultas);
    for (let i = 0; i < consultas.length; i++) {
      const dataHoraVet = consultas[i].consultationDate.split(" ");
      const data = dataHoraVet[0];
      const hora = dataHoraVet[1];
      const dataVet = data.split("/");
      const dateFormatted = `${dataVet[2]}-${dataVet[1]}-${dataVet[0]}T${hora}`;
      console.log("dateFormatted", dateFormatted);
      const first = new Date(dateFormatted);
      let second = new Date(dateFormatted);
      second.setTime(second.getTime() + 1 * 60 * 60 * 1000);

      events.push({
        id: consultas[i].id,
        name:
          consultas[i].status == "SCHEDULED"
            ? consultas[i].patient.name
            : `${consultas[i].patient.name} ✔️`,
        start: first,
        end: second,
        color: this.colors[i % this.colors.length],
        timed: true,
      });
    }

    this.events = events;
  }
  getEventColor(event: any) {
    return event.color;
  }
  rnd(a: number, b: number) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }
  mounted() {
    const hora = new Date().getHours();
    const minuto = new Date().getMinutes();
    console.log("new Date().getTime()", `${hora - 1}:${minuto}`);
    (this.$refs as any).calendar.scrollToTime(`${hora - 1}:${minuto}`);
  }
  constructor() {
    super();
    this.list();
    // (this.$refs as any).calendar.scrollToTime("08:00");
  }
}
