






















import { Component, Vue, Watch } from "vue-property-decorator";

import TopBar from "@/components/TopBar.vue";
import UserDTO from "@/models/UserDTO";

interface MenuItem {
  titulo: string;
  descricao: string;
  to: string;
  img: string;
}

@Component({
  components: {
    TopBar,
  },
})
export default class Home extends Vue {
  medicos: MenuItem = {
    titulo: "Médicos",
    descricao:
      "Tenha acesso a todos os dados referentes aos Médicos da sua clínica. Adicione novos, exclua-os ou edite suas informações.",
    to: "/medicos",
    img: "medico",
  };
  atendentes: MenuItem = {
    titulo: "Atendentes",
    descricao:
      "Tenha acesso a todos os dados referentes aos Atendentes da sua clínica. Adicione novos, exclua-os ou edite suas informações.",
    to: "/atendentes",
    img: "atendente",
  };
  pacientes: MenuItem = {
    titulo: "Pacientes",
    descricao:
      "Tenha acesso a todos os dados referentes aos Pacientes da sua clínica. Adicione novos, exclua-os ou edite suas informações.",
    to: "/pacientes",
    img: "paciente",
  };

  atendenteConsultas: MenuItem = {
    titulo: "Consultas",
    descricao:
      "Tenha acesso a todos os dados referentes às Consultas da sua clínica. Adicione novos, exclua-os ou edite suas informações.",
    to: "/atendentes/consultas",
    img: "consulta",
  };

  medicoConsultas: MenuItem = {
    titulo: "Consultas",
    descricao:
      "Tenha acesso a todos os dados referentes às Consultas da sua clínica. Adicione novos, exclua-os ou edite suas informações.",
    to: "/medicos/consultas",
    img: "consulta",
  };
  menuItens: MenuItem[] = [];
  getImgUrl(url: string) {
    var images = require.context("../assets/", false, /\.png$/);
    return images("./" + url + ".png");
  }
  goTo(link: string) {
    this.$router.push(link);
  }

  get user() {
    return this["$store"].state.user as UserDTO;
  }

  @Watch("user")
  changeUser() {
    if (this.user.roles.some((el) => el.authority == "ROLE_ADMIN")) {
      this.menuItens.push(this.medicos);
      this.menuItens.push(this.atendentes);
      this.menuItens.push(this.pacientes);
    } else if (this.user.roles.some((el) => el.authority == "ROLE_ATENDENTE")) {
      this.menuItens.push(this.atendenteConsultas);
      this.menuItens.push(this.pacientes);
    } else if (this.user.roles.some((el) => el.authority == "ROLE_MEDICO")) {
      this.menuItens.push(this.medicoConsultas);
      this.menuItens.push(this.pacientes);
    } else {
      this.$router.push("/home");
    }
  }
  constructor() {
    super();
  }
}
