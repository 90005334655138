

































import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class App extends Vue {
  constructor() {
    super();
  }
  get error() {
    return this["$store"].state.error;
  }
  get success() {
    return this["$store"].state.success;
  }
  closeError() {
    this["$store"].commit("setError", false);
  }
  showErrorMessage(): boolean {
    const { error } = this.$store.state;
    if (typeof error !== "boolean") {
      if (error && error.toString().length)
        setTimeout(this.closeError, error.toString().length * 80);
      else setTimeout(this.closeError, 5000);
    }
    return typeof error !== "boolean";
  }
  closeSuccess() {
    this["$store"].commit("setSuccess", false);
  }
  showSuccessMessage(): boolean {
    const { success } = this["$store"].state;
    if (typeof success !== "boolean")
      setTimeout(this.closeSuccess, success.length * 110);
    return typeof success !== "boolean";
  }
}
