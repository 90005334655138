
















import Vue from "vue";
import Component from "vue-class-component";
import EnderecoInput from "@/components/EnderecoInput.vue";
import { Prop, Watch } from "vue-property-decorator";
import MedicoService from "@/services/MedicoService";

@Component({
  components: {
    EnderecoInput,
  },
})
export default class DialogDeleteMedico extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop() pessoaId!: number;
  @Watch("dialog")
  dialogRootChange() {
    this.$emit("update:dialog", this.dialog);
  }
  async deletePessoa() {
    await MedicoService.delete(this.pessoaId);
    this.dialog = false;
  }
}
